.services-section {
  width: 100%;
  display: flex;
  background-color: #F6F4F4;
  color: #3E6B94;
  padding: 0 0 3rem;
}

.services-container {
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.services-container h1 {
  color: #3E6B94;
  padding: 2.5rem;
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
}

.services {
  display: flex;
  flex-direction: column;
}

.services-article {
  font-size: 0.9rem;
  padding: 1rem;
}

.services-article div {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.services-icon {
  font-size: 4rem;
}

.services-article h4 {
  font-weight: 550;
  margin: 2rem 0;
  padding: 0;
}

@media screen and (min-width: 768px) {

  .services-container {
    width: 100%;
    max-width: 1400px;
    padding: 0 5rem;
  }
  
  .services {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .services-article {
    height: fit-content;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
  }

  .services-article div {
    margin-top: 2rem;
  }

  .services-icon {
    font-size: 5rem;
  }

  .services-article h4 {
    font-size: 1.75rem;
  }

  .services-article p {
    font-size: 1.05rem;
  }
  
}