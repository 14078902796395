/* desktop-header styles */

.desktop-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  display: none;
  z-index: 99;
}

.desktop-nav {
  margin: 1.5rem auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-logo {
  width: 12rem;
}

.desktop-navlink {
  color: #FFF;
  text-decoration: none;
  font-weight: 600;
  margin: 0 3rem;
}

.contact-btn {
  width: 10rem;
  padding: 0.75rem;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 35px;
}

.desktop-navlink:hover,
.contact-btn:hover {
  cursor: pointer;
  color: #d4d4d5;
}

.contact-btn:hover {
  color: #180430;
  background-color: #ffffff;
  transition: 0.5s;
}

/* mobile-header styles */

.mobile-header {
  width: 100%;
  height: 5.5rem;
  position: sticky;
  top: 0;
  transition: 0.5s;
  display: flex;
  z-index: 99;
}

.mobile-nav {
  width: 100%;
  margin: auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.mobile-logo {
  width: 6rem;
}

.menu-closebtn,
.menu-openbtn {
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.25rem;
  color: #B4B5B9;
}

.mobile-menu {
  width: 100%;
  position: absolute;
  top: 3.75rem;
  left: 0;
  background-color: #180430;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.5s;
}

ul {
  padding: 0;
}

.mobile-navlink {
  color: #fff;
  display: flex;
  margin: 2rem;
  font-size: 1.25rem;
  text-decoration: none;
}


.mobile-navlink li {
  text-decoration: none;
  margin: 0;
  display: flex;
  align-items: center;
}

.link-icon {
  margin-right: 0.5rem;
}

@media screen and (min-width: 948px) {

  .desktop-header {
    display: block;
  }

  .desktop-nav {
    max-width: 1400px;
    margin: 1.5rem auto;
    padding: 0 5rem;
  }

  .mobile-header {
    display: none;
  }
}