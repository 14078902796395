@import url('https://fonts.googleapis.com/css2?family=Numans&display=swap');

body {
  margin: 0;
  font-family: 'Numans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.a:visited {
  color: #d4d4d5;
}