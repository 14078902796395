.intro-section {
  background-image: url('../assets/intro.webp');
  background-size: cover;
  color: #ffffff;
}

.intro-article {
  padding: 3rem 2rem 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.intro-article h1 {
  font-size: 3.5rem;
  margin-bottom: 3rem;
  text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px black;
}

.slider-container {
  width: 100%;
  margin: 1rem 0 3rem;
  display: flex;
  align-items: center;
}

.slider {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.slider-header {
  color: #FFF;
  margin: 2rem auto 0;
  text-align: center;
  text-shadow: -0.25px 0 black, 0 0.25px black, 0.25px 0 black, 0 -0.25px black;
}

.slide-track {
  width: 15rem;
  display: flex;
  width: calc(12rem * 10);
  animation: scroll 40s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-12rem * 14));
  }
}

.slide {
  height: 6rem;
  width: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-img {
  width: 10rem;
  height: 3.5rem;
  border: 1px solid #676666;
  border-radius: 5px;
  margin: 0.5rem;
}

.slide-img:hover {
  cursor: pointer;
  width: 10.5rem;
  height: 3.5rem;
}

@media screen and (min-width: 948px) {

  .intro-section {
    width: 100%;
    height: 800px;
  }

  .intro-article {
    height: 100%;
    justify-content: flex-start;
    max-width: 1400px;
    padding: 12rem 5rem 0;
  }

  .intro-article h1 {
    width: 50%;
    font-size: 5rem;
  }
  
  .intro-article p {
    width: 50%;
    font-size: 1.1rem;
  }

  .slider-container {
    margin: 3rem 0 0;
  }

  .slider {
    width: 60%;
  }

  .slider-header {
    margin: 0 auto;
  }

}