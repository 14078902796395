.aboutus-section {
  width: 100%;
  display: flex;
  padding: 0 0 3rem;
}

.aboutus-container {
  margin: 0 auto;
  padding: 0 2rem;
  color: #3E6B94;
}

.aboutus-container h1 {
  padding: 2.5rem;
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
}

.aboutus {
  display: flex;
  flex-direction: column;
}

.aboutus-text p {
  font-size: 0.9rem;
  margin: 2rem 0 0;
  line-height: 1.5rem;
}

.aboutus-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.aboutus-img {
  margin: 2rem 0 1rem;
  border-radius: 50%;
  height: 15rem;
  width: 15rem;
}

.aboutus-quote {
  margin: 1rem;
}

.aboutus-quote p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-style: italic;
}

.aboutus-info h6 {
  width: 100%;
  margin-top: 1rem;
  text-align: left;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {

  .aboutus-container {
    width: 100%;
    max-width: 1400px;
    padding: 0 5rem;
  }

  .aboutus {
    margin-top: 3rem;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
  }

  .aboutus-text p {
    font-size: 1.2rem;
    margin: 0 0 1rem;
    line-height: 2rem;
  }

  .aboutus-img {
    margin: 1rem;
    height: 18rem;
    width: 18rem;
  }

  .aboutus-quote {
    margin: 1rem auto;
  }

  .aboutus-quote {
    width: 30rem;
    padding: 1rem 2rem;
  }

}